import React, { FunctionComponent, useState, useEffect } from "react"
import classNames from "classnames"
import { Helmet } from "react-helmet"
import { PosterPlayer } from "./video-header-poster-player"
import { MainPlayer } from "./video-header-main-player"
import styles from "./video-header.module.scss"
import { Header, HeaderProps } from "@components/header/header"
import { usePageScroll } from "@components/page/page"
import { Icon } from "@components/icon/icon"
import { Markdown } from "@components/markdown/markdown"

export interface VideoHeaderProps extends HeaderProps {
  videoId?: string
  posterVideoId?: string
  heading?: string
  quote?: string
  attribution?: string
  buttonText?: string
}

export const VideoHeader: FunctionComponent<VideoHeaderProps> = ({
  videoId,
  posterVideoId,
  heading,
  quote,
  attribution,
  buttonText
}) => {
  const [isLaunched, setIsLaunched] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)

  const { scrollEnabled, setScrollEnabled } = usePageScroll()

  useEffect(() => {
    setScrollEnabled(!isPlaying || !isLaunched)
  }, [isPlaying, isLaunched, setScrollEnabled])

  const handleEnd = () => {
    setIsLaunched(false)
    setIsPlaying(false)
  }

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: scrollEnabled ? styles.disableHorizontalScroll : ""
        }}
      />
      <Header
        className={classNames(styles.navbar, {
          [styles.hidden]: isLaunched && isPlaying
        })}
        variant="clear"
        searchPlaceholder="Search"
      />
      <div
        className={classNames(styles.playerWrapper, {
          [styles.launched]: isLaunched
        })}
      >
        {isLaunched && isPlaying && <div className={styles.mainPlayerTint} />}
        <MainPlayer
          videoId={videoId}
          isLaunched={isLaunched}
          className={classNames(styles.player, {
            [styles.visible]: isLaunched
          })}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnd={handleEnd}
        />
        <PosterPlayer
          videoId={posterVideoId}
          className={classNames(styles.player, {
            [styles.visible]: !isLaunched
          })}
        >
          <div className={styles.posterContent}>
            <Markdown content={heading || ""} className={styles.title} />
            <Markdown
              {...reactMarkdownProps}
              content={quote || ""}
              className={styles.subTitle}
            />
            <span className={styles.attribution}>{attribution}</span>
            <div className={styles.spacer} />
            <div
              className={styles.launchButton}
              onClick={() => setIsLaunched(true)}
              data-testid="videoHeaderLaunchButton"
            >
              <Icon className={styles.playIcon16} variant="16-play-circle" />
              <Icon className={styles.playIcon36} variant="36-play-circle" />
              {buttonText}
            </div>
          </div>
        </PosterPlayer>
      </div>
    </>
  )
}
