import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { ContentfulTheHolinessOfGodLandingPage } from "../../../graphql/types"
import { defaultMetadata, PageOpenGraphImage } from "../../core/constants"
import styles from "./holiness-of-god.module.scss"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import { mapAboutFounderToView } from "@features/about/mappers/about-founder-mapper"
import AdditionalInfo from "@components/AdditionalInfo/additionalInfo"
import { TheologicalFeatures } from "@features/support/components/theological-features/theological-features"
import { ProductCTA } from "@features/support/components/product-cta/product-cta"
import { TeachingSeriesCTA } from "@features/support/components/teaching-series-cta/teaching-series-cta"
import { GoDeeper } from "@features/support/components/go-deeper/go-deeper"
import { AboutAuthorDetails } from "@features/about/components/about-author/about-author"
import { FaQs } from "@features/give-partner/components/FaQs/FaQs"
import { StartJourney } from "@features/about/components/start-journey/start-journey"

export const HolinessOfGodPage: FunctionComponent<
  PageProps<{
    contentfulTheHolinessOfGodLandingPage: ContentfulTheHolinessOfGodLandingPage
  }>
> = (props) => {
  const {
    seo,
    posterVideo,
    video,
    heroTitle,
    heroSubtitle,
    heroButtonText,
    openingTitle,
    openingDescription,
    openingButtonText,
    openingButtonUrl,
    featureImage,
    features,
    productCta,
    exploreCta,
    journeyCta,
    deeperTitle,
    deeperDescription,
    deeperCards,
    founder: _founder,
    faQs,
    faqTitle
  } = props?.data?.contentfulTheHolinessOfGodLandingPage || {}

  const metadataOverrides = {
    title: seo?.title || defaultMetadata.title,
    description: seo?.description || defaultMetadata.description,
    openGraphImage: seo?.image?.fixed?.src || PageOpenGraphImage.HolinessOfGod
  }

  const founder = mapAboutFounderToView({
    founderContentBlock: _founder,
    founderFeaturedWorks: [],
    founderResources: []
  })

  const headerVideoOptions = {
    posterVideoId: posterVideo?.brightcoveId || "",
    videoId: video?.brightcoveId || "",
    heading: heroTitle?.childMarkdownRemark?.rawMarkdownBody || "",
    quote: heroSubtitle || "",
    attribution: "",
    buttonText: heroButtonText || ""
  }

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
      isInfoIconShown={true}
      pageOptions={{
        headerVariant: "video",
        headerVideoOptions
      }}
    >
      <AdditionalInfo
        title={openingTitle?.childMarkdownRemark?.rawMarkdownBody || ""}
        body={openingDescription?.childMarkdownRemark?.rawMarkdownBody || ""}
        buttonText={openingButtonText || ""}
        buttonUrl={openingButtonUrl || ""}
      />
      <TheologicalFeatures
        image={featureImage?.fluid?.src || ""}
        items={
          features?.map((feature) => ({
            name: feature?.title?.childMarkdownRemark?.rawMarkdownBody || "",
            body:
              feature?.description?.childMarkdownRemark?.rawMarkdownBody || ""
          })) || []
        }
      />
      <ProductCTA
        title={productCta?.title || ""}
        subtitle={productCta?.subtitle || ""}
        body={productCta?.body?.childMarkdownRemark?.rawMarkdownBody || ""}
        image={productCta?.image?.fluid?.src || ""}
        primaryButtonText={productCta?.buttonText || ""}
        primaryButtonUrl={productCta?.buttonUrl || ""}
        secondaryButtonText={productCta?.button2Text || ""}
        secondaryButtonUrl={productCta?.button2Url || ""}
      />
      <TeachingSeriesCTA
        title={exploreCta?.title || ""}
        subtitle={exploreCta?.subtitle || ""}
        body={exploreCta?.body?.childMarkdownRemark?.rawMarkdownBody || ""}
        image={exploreCta?.image?.fluid?.src || ""}
        buttonText={exploreCta?.buttonText || ""}
        buttonUrl={exploreCta?.buttonUrl || ""}
      />
      <GoDeeper
        title={deeperTitle?.childMarkdownRemark?.rawMarkdownBody || ""}
        body={deeperDescription?.childMarkdownRemark?.rawMarkdownBody || ""}
        cards={
          deeperCards?.map((card) => ({
            title: card?.title?.childMarkdownRemark?.rawMarkdownBody || "",
            body: card?.description?.childMarkdownRemark?.rawMarkdownBody || "",
            image: card?.image?.fluid?.src || "",
            link: card?.urlLink || "",
            callout: card?.buttonText || ""
          })) || []
        }
      />
      {founder && (
        <AboutAuthorDetails details={founder} className={styles.founder} />
      )}
      <StartJourney
        title={journeyCta?.title || ""}
        subtitle={journeyCta?.body?.childMarkdownRemark?.rawMarkdownBody || ""}
        image={journeyCta?.image?.fluid?.src || ""}
        primaryCTAText={journeyCta?.buttonText || ""}
        primaryCTALink={journeyCta?.buttonUrl || ""}
        secondaryCTAText={journeyCta?.button2Text || ""}
        secondaryCTALink={journeyCta?.button2Url || ""}
      />
      <FaQs
        title={faqTitle || ""}
        questions={
          faQs?.map((faq) => ({
            title: faq?.title || "",
            subtitle: faq?.subtitle || "",
            body: {
              childMarkdownRemark: {
                rawMarkdownBody:
                  faq?.body?.childMarkdownRemark?.rawMarkdownBody || ""
              }
            },
            image: {
              fluid: {
                src: faq?.image?.fluid?.src || ""
              }
            }
          })) || []
        }
      />
    </Page>
  )
}

export default HolinessOfGodPage

export const query = graphql`
  query HolinessOfGodPage {
    contentfulTheHolinessOfGodLandingPage {
      seo {
        image {
          ...ContentfulImage
        }
        title
        description
      }
      heroTitle {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      heroSubtitle
      heroButtonText
      openingTitle {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      openingDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      openingButtonText
      openingButtonUrl
      posterVideo {
        brightcoveId
      }
      video {
        brightcoveId
      }
      featureImage {
        ...ContentfulImage
      }
      features {
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        buttonText
        urlLink
      }
      productCta {
        title
        subtitle
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          ...ContentfulImage
        }
        buttonText
        buttonUrl
        button2Text
        button2Url
      }
      exploreCta {
        title
        subtitle
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          ...ContentfulImage
        }
        buttonText
        buttonUrl
        button2Text
        button2Url
      }
      deeperTitle {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      deeperDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      deeperCards {
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        overline
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        buttonText
        urlLink
        image {
          ...ContentfulImage
        }
      }
      founder {
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        buttonText
        urlLink
        image {
          ...ContentfulImage
        }
      }
      journeyCta {
        title
        subtitle
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          ...ContentfulImage
        }
        buttonText
        buttonUrl
        button2Text
        button2Url
      }
      faqTitle
      faQs {
        title
        subtitle
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          ...ContentfulImage
        }
        buttonText
        buttonUrl
        button2Text
        button2Url
      }
    }
  }
`
