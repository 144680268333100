import React, { createContext, FunctionComponent, useEffect } from "react"
import { graphql } from "gatsby"
import { useMachine } from "@xstate/react"
import { DailyVideoPageQuery } from "../../../../graphql/types"
import { PageProps } from "../../../types/page"
import { mapSourceDailyVideoToView } from "../mappers/daily-video-mapper"
import { DailyVideoPage } from "../components/daily-video-page/daily-video-page"
import { Page } from "../../../components/page/page"
import { PlaylistItem } from "../../../types/playlist-item"
import { DailyVideoData } from "../daily-video-types"
import {
  ContentLockBootstrapDataKey,
  contentUnlockedMachine
} from "../../../machines/contentLockMachine.machine"
import { IServiceContext } from "../../../contexts/service-context"

export const ServiceContext = createContext<IServiceContext | null>(null)

export const DailyVideoTemplate: FunctionComponent<
  PageProps<
    DailyVideoPageQuery,
    {
      recentVideos: PlaylistItem<DailyVideoData>[]
      videoContentfulId: string
    }
  >
> = ({ data, ...props }) => {
  const [{ context }, send, contentLockService] = useMachine(
    contentUnlockedMachine
  )

  const dailyVideoData = data?.contentfulDailyVideo
  const { metadata } = dailyVideoData || {}

  const dailyVideo = mapSourceDailyVideoToView(
    data!,
    props?.pageContext?.initialData?.recentVideos || []
  )

  useEffect(() => {
    const shouldBootstrapContentLock =
      !context?.dailyVideo.isContentUnlocked &&
      typeof context?.dailyVideo.isContentUnlocked === "object" &&
      !context?.dailyVideo.contentLockFlag &&
      dailyVideo

    if (shouldBootstrapContentLock) {
      send({
        type: "BOOTSTRAP_CONTENT_LOCK_STATE",
        data: {
          loading: false,
          ligCode: dailyVideo.video.ligCode,
          title: dailyVideo.video.title,
          isAlwaysUnlocked: true,
          isMaintenanceMode: false,
          contentLockFlag: null,
          isContentUnlocked: false,
          key: ContentLockBootstrapDataKey.dailyVideo
        }
      })
    }
  }, [context, dailyVideo])

  const metadataOverrides = {
    title: metadata?.title || dailyVideo.metadata.title,
    description: metadata?.description || dailyVideo.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || dailyVideo.metadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{ ...dailyVideo.metadata, ...metadataOverrides }}
      pageOptions={{ headerVariant: "cream" }}
    >
      <ServiceContext.Provider
        value={{ contentLockService, alwaysUnlocked: true }}
      >
        <DailyVideoPage
          dailyVideo={dailyVideo}
          contentfulId={props.pageContext.contentfulId!}
          videoContentfulId={
            props?.pageContext?.initialData?.videoContentfulId || ""
          }
        />
      </ServiceContext.Provider>
    </Page>
  )
}

export default DailyVideoTemplate

export const query = graphql`
  query DailyVideoPage($id: String, $topicCodes: [String]) {
    contentfulDailyVideo(id: { eq: $id }) {
      ...DailyVideo
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      ...TopicDetailsList
    }
  }
`
