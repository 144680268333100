import React, { FunctionComponent, useEffect, useContext } from "react"
import classNames from "classnames"
import { Menu } from "@headlessui/react"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser"
import axios from "axios"
import { useSelector } from "@xstate/react"
import { useQuery } from "react-query"
import styles from "./header-auth.module.scss"
import {
  handleLogin,
  handleLogout,
  AuthContext,
  useAuthTokens
} from "@contexts/authContext"
import { Link } from "@components/link/link"
import { Icon } from "@components/icon/icon"
import type { User } from "src/machines/authMachine.machine"
import { accountUrl } from "@core/constants"

export type HeaderAuthVariant = "regular" | "homepage" | "homepage_scrolled"

export interface HeaderAuthProps {
  variant?: HeaderAuthVariant
  className?: string
  accountButtonClassName?: string
}

export const HeaderAuth: FunctionComponent<HeaderAuthProps> = ({
  variant = "regular",
  className,
  accountButtonClassName
}) => {
  const { inProgress, accounts, instance: msalInstance } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { accessToken } = useAuthTokens()
  const { service: authMachine } = useContext(AuthContext)
  const { isMinistryPartner, userDetails } = useSelector(
    authMachine,
    ({ context }) => context
  )

  const { data: mpResponse } = useQuery(
    `mp_status_${accessToken}`,
    async () => {
      const { data } = await axios.get<{ is_partner: boolean }>(
        `${process.env.GATSBY_DJANGO_REDIRECT_URL}/api/v1/user/library/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )

      return data
    },
    {
      enabled: Boolean(accessToken && !isMinistryPartner)
    }
  )

  useEffect(() => {
    if (mpResponse && !isMinistryPartner) {
      authMachine?.send({
        type: "SET_MINISTRY_PARTNER",
        isMinistryPartner: mpResponse.is_partner
      })
    }
  }, [mpResponse, isMinistryPartner])

  const userLoggingIn =
    !isAuthenticated &&
    (inProgress === InteractionStatus.Startup ||
      inProgress === InteractionStatus.HandleRedirect)

  // if (msalInstance.getAllAccounts().length > 0) {
  //   msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  // }

  const account = accounts?.[0]

  useEffect(() => {
    const firstName = `${account?.idTokenClaims?.given_name || ""}`
    const lastName = `${account?.idTokenClaims?.family_name || ""}`
    const name = `${firstName}${firstName && lastName ? " " : ""}${lastName}`
    const user: User = {
      username: `${account?.idTokenClaims?.email || ""}`,
      name,
      firstName,
      lastName,
      email: `${account?.idTokenClaims?.email || ""}`,
      sub: account?.idTokenClaims?.sub || "",
      exp: account?.idTokenClaims?.exp || null
    }

    if (userLoggingIn) {
      authMachine?.send({
        type: "LOGGING_IN"
      })
    } else if (isAuthenticated) {
      authMachine?.send({
        type: "LOGGED_IN",
        userDetails: user
      })
    } else {
      authMachine?.send({
        type: "LOGGED_OUT"
      })
    }
  }, [userLoggingIn, isAuthenticated, account, authMachine])

  return (
    <div
      className={classNames(
        styles.authButtons,
        styles[variant],
        variant,
        className
      )}
    >
      {userLoggingIn ? (
        <Icon
          className={classNames(styles.authButton, accountButtonClassName)}
          variant="40-userAvatar"
        />
      ) : userDetails ? (
        <Menu>
          <Menu.Button className={styles.headlessButton}>
            <Icon
              variant="40-userAvatar"
              className={classNames(styles.authButton, accountButtonClassName)}
            />
          </Menu.Button>
          <Menu.Items className={styles.dropDownMenu}>
            <Menu.Item>
              <a className={styles.items} href={`${accountUrl}/dashboard/`}>
                Account
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                className={styles.items}
                href={`${accountUrl}/dashboard/learning-library/`}
              >
                My Library
              </a>
            </Menu.Item>
            <Menu.Item>
              <Link
                className={styles.items}
                to={""}
                onClick={() => handleLogout(msalInstance)}
              >
                Sign out
              </Link>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      ) : (
        <Link
          className={styles.authButtonLink}
          to={""}
          onClick={() => handleLogin(msalInstance)}
        >
          <Icon
            className={classNames(styles.authButton, accountButtonClassName)}
            variant="40-userAvatar"
          />
        </Link>
      )}
    </div>
  )
}
