import React, { FunctionComponent } from "react"
import styles from "./start-journey.module.scss"
import { Button } from "@components/button/button"
import { Markdown } from "@components/markdown/markdown"

interface StartJourneyProps {
  title: string
  subtitle: string
  image: string
  primaryCTAText: string
  primaryCTALink: string
  secondaryCTAText: string
  secondaryCTALink: string
}

export const StartJourney: FunctionComponent<StartJourneyProps> = ({
  title,
  subtitle,
  image,
  primaryCTAText,
  primaryCTALink,
  secondaryCTAText,
  secondaryCTALink
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      <Markdown className={styles.subtitle} content={subtitle} />
    </div>
    <div className={styles.image}>
      <img src={image} />
    </div>
    <div className={styles.footer}>
      <Button to={primaryCTALink}>{primaryCTAText}</Button>
      <Button to={secondaryCTALink} variant="secondary">
        {secondaryCTAText}
      </Button>
    </div>
  </div>
)
