import React, { createContext, ReactNode, useContext, useRef } from "react"
import { Interpreter, State, PayloadSender } from "xstate"
import { useInterpret, useService } from "@xstate/react"
import { InteractionStatus } from "@azure/msal-browser"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { useQuery } from "react-query"
import authenticationMachine, {
  AuthenticationMachineContext as Context,
  AuthenticationMachineEvent as Events
} from "../machines/authMachine.machine"
import { useSegmentEvent } from "@hooks/use-segment-event"
import {
  loginRequest,
  tokenRequest as tokenRequestTemplate
} from "@utils/authConfig"
import { useLigonierUser } from "@hooks/useLigonierUser"

if (
  typeof window !== "undefined" &&
  process.env.GATSBY_ACTIVE_ENV === "development"
) {
  // inspect({
  //   iframe: false
  // })
}

type AuthProviderProps = { children: ReactNode }

type ServiceType = Interpreter<Context, any, Events>

type UseServiceHookValue = [State<Context, Events>, PayloadSender<Events>]

export const AuthContext = createContext<{
  service: ServiceType
  identified: React.MutableRefObject<boolean>
}>({ service: {} as ServiceType, identified: { current: false } })

function AuthProvider({ children }: AuthProviderProps): any {
  const service = useInterpret(authenticationMachine /*, { devTools: true }*/)
  const identified = useRef(false)

  return (
    <AuthContext.Provider value={{ service, identified }}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): UseServiceHookValue {
  const { service: context, identified } = useContext(AuthContext)

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider")
  }

  const { identify } = useSegmentEvent()
  const service = useService(context)
  const [state] = service
  const authUser = state?.context?.userDetails
  const { accessToken } = useAuthTokens()
  const ligonierUser = useLigonierUser(authUser, accessToken)

  const isLoggedIn = state?.value === "loggedIn"

  if (
    isLoggedIn &&
    ligonierUser &&
    ligonierUser.userId &&
    !identified.current
  ) {
    identify({
      id: ligonierUser?.userId,
      firstName: ligonierUser.firstName || "",
      lastName: ligonierUser.lastName || "",
      email: ligonierUser.email || ""
    })

    identified.current = true
  }

  return service
}

function useAuthTokens() {
  const { inProgress, accounts, instance: msalInstance } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { service: authMachine } = useContext(AuthContext)

  const account = accounts?.[0]
  const shouldMakeTokenRequest =
    inProgress === InteractionStatus.None && isAuthenticated && Boolean(account)
  const { data } = useQuery(
    "token",
    async () => {
      const tokenRequest = {
        ...tokenRequestTemplate,
        account
      }

      console.log("CALLING ACQUIRETOKENSILENT, tokenRequest=", tokenRequest)

      try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest)

        console.log("ACQUIRETOKENSILENT RESULT:", response)

        return response
      } catch (e) {
        console.log("ACQUIRETOKENSILENT ERROR:", e)

        // For now the business does not want to follow Azure's design pattern
        // so we will be "sudo" logging the user out through the auth machine instead

        authMachine.send("LOGGED_OUT")
      }
    },
    { enabled: shouldMakeTokenRequest, cacheTime: 0 }
  )

  return {
    isAuthenticated,
    idToken: data?.idToken,
    accessToken: data?.accessToken
  }
}

function handleLogin(msalInstance: any) {
  // msalInstance.loginPopup(loginRequest)
  msalInstance.loginRedirect(loginRequest)
}

function handleLogout(msalInstance: any) {
  // msalInstance.logoutPopup()
  msalInstance.logoutRedirect()
}

export { AuthProvider, useAuth, useAuthTokens, handleLogin, handleLogout }
