import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "../link/link"
import styles from "./footer.module.scss"

interface FooterLanguagesProps {
  basePath?: string
}

export const footerLanguageLocales = [
  {
    subdomain: "ar",
    label: "Arabic"
  },
  {
    subdomain: "bn",
    label: "Bengali"
  },
  {
    subdomain: "zh",
    label: "Chinese"
  },
  {
    subdomain: "fa",
    label: "Farsi"
  },
  {
    subdomain: "fr",
    label: "French"
  },
  {
    subdomain: "de",
    label: "German"
  },
  {
    subdomain: "hi",
    label: "Hindi"
  },
  {
    subdomain: "id",
    label: "Indonesian"
  },
  {
    subdomain: "ja",
    label: "Japanese"
  },
  {
    subdomain: "ko",
    label: "Korean"
  },
  {
    subdomain: "pt",
    label: "Portuguese"
  },
  {
    subdomain: "ru",
    label: "Russian"
  },
  {
    subdomain: "es",
    label: "Spanish"
  },
  {
    subdomain: "ta",
    label: "Tamil"
  },
  {
    subdomain: "te",
    label: "Telugu"
  },
  {
    subdomain: "tr",
    label: "Turkish"
  },
  {
    subdomain: "uk",
    label: "Ukrainian"
  },
  {
    subdomain: "ur",
    label: "Urdu"
  }
]

export const FooterLanguages: FunctionComponent<FooterLanguagesProps> = () => (
  <div className={styles.languagesWrapper}>
    <div className={styles.languages}>
      <FormattedMessage tagName="label" id="footer-available-in" />
      <ul>
        {footerLanguageLocales.map(({ subdomain, label }) => (
          <li key={subdomain}>
            <Link to={`https://${subdomain}.ligonier.org`}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
)
