import React, {
  PropsWithChildren,
  useState,
  Fragment,
  useEffect,
  useMemo,
  useContext
} from "react"
import classNames from "classnames"
import { BaseProductVariant } from "../../features/store/store-types"
import { PlaylistItem } from "../../types/playlist-item"
import { Player, PlayerContent } from "../player/player"
import { Audio } from "../audio/audio"
import { Playlist } from "../playlist/playlist"
import { PlayerSchema } from "../player/player-schema"
import {
  ContentLockBootstrapDataKey,
  ContentLockData,
  ContentLockMachineProps
} from "../../machines/contentLockMachine.machine"
import {
  IServiceContext,
  useContentLockServiceContext
} from "../../contexts/service-context"
import { MediaTypeSwitch, MediaType } from "./media-type-switch"
import { BlockedContentMessage } from "./blocked-content-message"
import styles from "./player-with-playlist.module.scss"
import { OverlayWithMessage } from "./overlay-with-message"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"
import { Teacher } from "@src-types/teacher"

export interface PlayerWithPlaylistProps<T> {
  mediaId?: string
  image?: string
  items: PlaylistItem<T>[]
  onItemClick: (item: PlaylistItem<T>) => void
  paidContentVariants?: BaseProductVariant[] | null
  playerContent?: PlayerContent
  playlistTitleOnMobile: string
  playlistTeachers?: Teacher[]
}

export function PlayerWithPlaylist<T>({
  mediaId,
  items,
  image,
  onItemClick,
  paidContentVariants,
  playerContent,
  playlistTitleOnMobile,
  playlistTeachers = []
}: PropsWithChildren<PlayerWithPlaylistProps<T>>): JSX.Element | null {
  const [allowedMediaTypes, setAllowedMediaTypes] = useState<MediaType[]>([])
  const [currentMediaType, setCurrentMediaType] = useState<MediaType>("video")
  const { ContentLockContext, contextKey } = useContentLockServiceContext()
  const {
    contentLockService,
    isLoading,
    canViewPremiumSeries,
    alwaysUnlocked
  } = useContext(ContentLockContext as React.Context<IServiceContext>)

  const [contentLockContext, setContentLockContext] =
    useState<ContentLockData | null>(null)

  useEffect(() => {
    if (contentLockService) {
      const contentLockSubscription = contentLockService.subscribe(
        (state: { context: ContentLockMachineProps }) => {
          setContentLockContext(
            state.context[contextKey as ContentLockBootstrapDataKey]
          )
        }
      )

      return () => {
        contentLockSubscription.unsubscribe()
      }
    }
  }, [contentLockService])

  const currentMediaItem = useMemo(() => {
    return items.find((item) => item.mediaId === mediaId)
  }, [items, mediaId])

  useEffect(() => {
    const list: MediaType[] = []

    if (currentMediaItem?.videoId) {
      list.push("video")
    }

    if (currentMediaItem?.audioUrl) {
      list.push("audio")
    }

    setAllowedMediaTypes(list)

    if (list.length) {
      setCurrentMediaType(list[0])
    }
  }, [currentMediaItem])

  const handleItemClick = (mediaId: string) => {
    const listItem = items.find((item) => item.mediaId === mediaId)!

    onItemClick(listItem)
  }

  const handleGoToFirstItem = () => {
    onItemClick(items[0])
  }

  const isFirstItem = items[0]?.mediaId === currentMediaItem?.mediaId

  const isContentUnlocked =
    isFirstItem ||
    alwaysUnlocked ||
    process.env.GATSBY_FEATURE_FLAG_CONTENT_LOCK_OFF === "true" ||
    canViewPremiumSeries

  if (!currentMediaItem) {
    return null
  }

  // alwaysUnlocked and isFirstItem aren't async vars so we don't care about isLoading
  // if those are true, we don't want to show a loading spinner if the content is going
  // to show anyway
  const showLoading = !alwaysUnlocked && !isFirstItem && isLoading

  if (
    paidContentVariants === null &&
    process.env.GATSBY_SHOW_PAID_VARIANTS_WARNINGS === "true"
  ) {
    console.warn(
      `'${currentMediaItem.title}' with media id '${currentMediaItem.mediaId}' has returned 'null' for paidContentVariants`
    )
  }

  const shouldShowSwitchMedia =
    allowedMediaTypes.length > 1 && isContentUnlocked

  const fluidImage = getFluidGatsbyImage(image || "", { maxWidth: 1400 })

  const shouldShowFallbackImage =
    !currentMediaItem?.videoId && !currentMediaItem?.audioUrl

  return (
    <div className={styles.playerWrapper}>
      {shouldShowSwitchMedia && (
        <MediaTypeSwitch
          className={styles.mediaTypeSwitch}
          type={currentMediaType}
          onClick={setCurrentMediaType}
        />
      )}
      {shouldShowFallbackImage ? (
        <OverlayWithMessage
          text="This message is no longer available."
          image={image}
        />
      ) : contentLockContext?.isMaintenanceMode ? (
        <OverlayWithMessage
          text="This media is not currently available. We hope to resolve the issue quickly."
          image={image}
        />
      ) : showLoading ? (
        <OverlayWithMessage showSpinner image={image} />
      ) : !isContentUnlocked ? (
        <BlockedContentMessage
          className={styles.blockedMessage}
          image={image}
          paidContentVariants={paidContentVariants || []}
          onButtonClick={handleGoToFirstItem}
          playlistTeachers={playlistTeachers}
          mediaType={currentMediaType}
        />
      ) : (
        <Fragment>
          {currentMediaType === "video" ? (
            <Fragment>
              <Player
                videoId={currentMediaItem ? currentMediaItem.videoId : ""}
                className={styles.player}
                content={playerContent}
              />
              <PlayerSchema
                playerContent={playerContent}
                item={currentMediaItem}
              />
            </Fragment>
          ) : (
            <div
              className={classNames(styles.player, styles.audioPlayer)}
              style={{ backgroundImage: `url("${fluidImage?.src || image}")` }}
            >
              <Audio
                title={currentMediaItem?.title || "No title."}
                src={currentMediaItem?.audioUrl!}
                className={styles.audio}
                audioId={currentMediaItem!.title}
              />
            </div>
          )}
        </Fragment>
      )}
      {items.length && (
        <Playlist
          onItemClick={handleItemClick}
          className={styles.playlist}
          initialPlayingItemId={mediaId!}
          items={items}
          playlistTitleOnMobile={playlistTitleOnMobile}
        />
      )}
    </div>
  )
}
