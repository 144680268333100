import React, { createContext, FunctionComponent, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import { useMachine } from "@xstate/react"
import { ConferenceDetailPageQuery } from "../../../../graphql/types"
import { PageProps, PageContext } from "../../../types/page"
import { mapSourceEventListToView } from "../../events/mappers/event-list-mapper"
import { mapSourceConferenceToView } from "../mappers/conference-mapper"
import { mapProductListToRelatedProductsView } from "../../store/mappers/related-products-mapper"
import { ConferencePage } from "../components/conference-page/conference-page"
import { Page } from "../../../components/page/page"
import { BreadcrumbResource } from "../../../components/breadcrumbs/breadcrumbs"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"
import { PathPrefix } from "../../../core/constants"
import {
  ContentLockBootstrapDataKey,
  contentUnlockedMachine
} from "../../../machines/contentLockMachine.machine"
import { IServiceContext } from "../../../contexts/service-context"

export const ServiceContext = createContext<IServiceContext | null>(null)

export interface ConferencePageContext extends PageContext {
  mediaId: string | null
  contentfulId: string
  seriesContentfulId: string
}

export interface ConferencePageProps<Query> extends PageProps<Query> {
  pageContext: ConferencePageContext
}

export const ConferenceDetail: FunctionComponent<
  ConferencePageProps<ConferenceDetailPageQuery>
> = ({ data, ...props }) => {
  const [{ context }, send, contentLockService] = useMachine(
    contentUnlockedMachine
  )

  const publishOnSite = data?.contentfulSeries?.publishOnSite

  const { mediaId, contentfulId, seriesContentfulId } = props.pageContext
  const conference = mapSourceConferenceToView(data!, mediaId)
  const upcomingEvents = mapSourceEventListToView(data!.allEvent!)
  const relatedProducts = mapProductListToRelatedProductsView(
    data!.contentfulSeries?.relatedProducts
  )

  const seriesData = data?.contentfulSeries

  useEffect(() => {
    const shouldBootstrapContentLock =
      !context.conference.isContentUnlocked &&
      typeof context.conference.isContentUnlocked === "object" &&
      !context.conference.contentLockFlag &&
      conference

    if (shouldBootstrapContentLock) {
      send({
        type: "BOOTSTRAP_CONTENT_LOCK_STATE",
        data: {
          loading: false,
          ligCode: conference?.currentVideo.ligCode,
          title: conference?.currentVideo.title,
          isAlwaysUnlocked: true,
          contentLockFlag: null,
          isMaintenanceMode: false,
          isContentUnlocked: false,
          key: ContentLockBootstrapDataKey.conference
        }
      })
    }
  }, [context, conference])

  if (!conference) {
    navigate("/404")

    return null
  }

  const metadataOverrides = {
    title: seriesData?.metadata?.title || conference.metadata.title,
    description:
      seriesData?.metadata?.description || conference.metadata?.description,
    openGraphImage:
      seriesData?.metadata?.image?.fixed?.src ||
      conference.metadata.openGraphImage,
    noIndex: !publishOnSite || false
  }

  const pageBreadcrumbs: BreadcrumbResource[] = [
    ...PageBreadcrumbs.Conferences,
    {
      label: conference.title,
      link: `${PathPrefix.Conferences}/${conference.slug}`
    }
  ]

  if (mediaId) {
    pageBreadcrumbs.push({
      label: conference.currentVideo.title,
      link: `${props.uri}`
    })
  }

  return (
    <Page
      {...props}
      metadata={{ ...conference.metadata, ...metadataOverrides }}
      pageOptions={{ headerVariant: "cream", breadcrumbs: pageBreadcrumbs }}
    >
      <ServiceContext.Provider
        value={{ contentLockService, alwaysUnlocked: true }}
      >
        <ConferencePage
          isConferenceStartPage={mediaId === null}
          mediaId={mediaId || undefined}
          conference={conference}
          upcomingEvents={upcomingEvents}
          relatedProducts={relatedProducts}
          contentfulId={contentfulId}
          seriesContentfulId={seriesContentfulId}
        />
      </ServiceContext.Provider>
    </Page>
  )
}

export default ConferenceDetail

export const query = graphql`
  query ConferenceDetailPage(
    $id: String
    $currentDate: Date
    $topicCodes: [String]
  ) {
    contentfulSeries(id: { eq: $id }) {
      ...Series
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      ...TopicDetailsList
    }
    allEvent(
      filter: { startDate: { gt: $currentDate } }
      sort: { fields: [startDate], order: ASC }
      limit: 3
    ) {
      nodes {
        ...EventNode
      }
    }
  }
`
