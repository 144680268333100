// Note: this machine should be reconfigured to properly handle
// MP status and new way of handling locked content, for now it should
// be in a working state. This should be done with it gets moved from
// gatsby so there aren't so many moving pieces.

import { assign, createMachine } from "xstate"

export interface ContentLockData {
  isContentViewable: boolean | null
  contentLockFlag: string | null
  isContentUnlocked: boolean | null
  isMaintenanceMode: boolean | null
  isAlwaysUnlocked: boolean | null
  ligCode: string | null
  title: string | null
  loading: boolean
}

export interface ContentLockMachineProps {
  dailyVideo: ContentLockData
  teachingSerie: ContentLockData
  conference: ContentLockData
}

export enum ContentLockBootstrapDataKey {
  conference = "conference",
  dailyVideo = "dailyVideo",
  teachingSerie = "teachingSerie"
}
export interface ContentLockBootstrapData {
  contentLockFlag: string | null
  ligCode: string | undefined
  title: string | undefined
  isAlwaysUnlocked: boolean
  isMaintenanceMode: boolean
  isContentUnlocked: boolean
  loading: boolean
  key: ContentLockBootstrapDataKey
}

const initialContext = {
  dailyVideo: {
    isAlwaysUnlocked: null,
    isContentViewable: null,
    contentLockFlag: null,
    isContentUnlocked: null,
    isMaintenanceMode: null,
    ligCode: null,
    title: null,
    loading: true
  },
  teachingSerie: {
    isAlwaysUnlocked: null,
    isContentViewable: null,
    contentLockFlag: null,
    isContentUnlocked: null,
    isMaintenanceMode: null,
    ligCode: null,
    title: null,
    loading: true
  },
  conference: {
    isAlwaysUnlocked: null,
    isContentViewable: null,
    contentLockFlag: null,
    isContentUnlocked: null,
    isMaintenanceMode: null,
    ligCode: null,
    title: null,
    loading: true
  }
}

export type Events = {
  type: "BOOTSTRAP_CONTENT_LOCK_STATE" | "UPDATE_CONTENT_LOCK_FLAG"
  data:
    | ContentLockBootstrapData
    | { contentLockFlag: string | null; key: ContentLockBootstrapDataKey }
}

export const contentUnlockedMachine = createMachine<
  ContentLockMachineProps,
  Events
>(
  {
    id: "contentLockMahine",
    initial: "idle",
    context: initialContext,
    states: {
      idle: {
        on: {
          UPDATE_CONTENT_LOCK_FLAG: {
            actions: ["updateContentLockFlag"]
          },
          BOOTSTRAP_CONTENT_LOCK_STATE: {
            actions: ["bootstrapContentLockState"]
          }
        }
      }
    }
  },
  {
    actions: {
      updateContentLockFlag: assign((ctx, { data }) => {
        return {
          [data.key]: {
            ...ctx[data.key],
            contentLockFlag: data.contentLockFlag,
            isMaintenanceMode: false
          }
        }
      }),
      bootstrapContentLockState: assign((ctx, { data }) => {
        return {
          ...ctx,
          [data.key]: data
        }
      })
    }
  }
)
